body {
  font-family: "Roboto", "sans-serif";
  overflow-x: hidden;
  height: auto;
}

h1,
h3 {
  font-weight: normal;
  margin: 5px;
}

span {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: inherit;
  transition: 0.2s ease;
}

a:hover {
  transform: translateX(2px);
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-around;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 10px;
  padding: 10px;
  border: 3px solid lightgray;
  border-radius: 2px;
}

.top-container img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.text-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 50px;
}

.bottom-container {
  width: 100%;
  height: auto;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 0px;
}

.sc-AxjAm:hover {
  /* opacity: 0.75; */
  background-color: #ddd;
}

/* Social Icon styles */
.social-container {
  margin: 10px;
}

.btn {
  padding: 5px;
  margin-right: 20px;
}

.fa {
  font-size: 32px;
  color: black;
}

.portfolio-container {
  height: 300px;
  width: calc(100% - 20px);
  margin: 0 auto;
}

.star-container {
  float: right;
}

.fa-star {
  font-size: 16px;
  color: #ccc;
  margin: 0 3px;
}

.checked {
  color: #555;
}

@media (max-width: 872px) {
  body {
    overflow-y: auto;
    height: auto;
  }
  .top-container {
    flex-direction: column;
    margin: 5px;
    padding: 10px;
    width: auto;
  }
  .text-container {
    margin-left: 10px;
  }
  h1 {
    font-size: 20px;
  }
  .bottom-container {
    padding: 5px 0;
  }
  .portfolio-container {
    width: 100%;
  }
}

.task-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links {
  width: 90%;
}

.link-dot {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background-color: #555;
}
